<div class="sm-header">
  <ng-content></ng-content>
  <div class="sm-card-list-layout projects">
    <ng-container *ngFor="let project of projects; trackBy: trackById; let index=index">
      <sm-project-card (deleteProjectClicked)="deleteProjectClicked.emit($event)"
        (projectCardClicked)="projectCardClicked.emit($event)"
        (newProjectClicked)="createNewProjectClicked.emit($event)"
        (projectNameChanged)="projectNameChanged.emit({id: project.id, name:$event})"
        (moveToClicked)="moveToClicked.emit($event)"
        *ngIf="index + totalVirtualCards < projects.length || noMoreProjects || showLast"
        [hideMenu]="['All Experiments'].includes(project.name) || $any(project).isRoot || isExample(project)"
        [project]="project" [projectsNames]="projectsNames">
      </sm-project-card>
    </ng-container>

    <div class="load-more" *ngIf="!noMoreProjects && projects?.length > 0">
      <button (click)="loadMore.emit()" class="btn btn-cml-primary load-more-btn" data-id="Load More">LOAD MORE</button>
    </div>
  </div>
</div>