<sm-card class="project-card" (click)="projectClicked()"
  [isExample]="!['All Experiments'].includes(project.name) && !isRootProject && (!project.company || ! project.company.id)"
  [isFolder]="true" [oneTabMode]="!(project?.sub_projects?.length>0)"
  [subFolderTitle]="project?.sub_projects?.length + ' sub projects'">
  <div secondTab class="sub-projects-list">
    <cdk-virtual-scroll-viewport [itemSize]="30" [minBufferPx]="440" [maxBufferPx]="660" class="h-100">
      <a *cdkVirtualFor="let subProject of project?.sub_projects; trackBy: trackById" class="d-flex align-items-center"
        smClickStopPropagation (click)="subProjectClicked(subProject.id)" [smTooltip]="subProject?.name"
        [innerHTML]="subProject?.name | breadcrumbsEllipsis"></a>
    </cdk-virtual-scroll-viewport>
  </div>
  <div header-content class="d-flex justify-content-between align-items-center card-name">
    <div>
      <sm-circle-counter [counter]="project.id === '*' ? '∞' : project.stats?.active?.total_tasks ?? '0'"
        [label]="project.basename"
        [type]="project.stats?.active?.total_tasks===0 ? circleTypeEnum.empty : circleTypeEnum.pending"
        [isTotal]="true"></sm-circle-counter>
      <!-- <sm-inline-edit #projectName
                    class="title edit-name dark"
                    [originalText]="project.basename"
                    [editable]="true"
                    [minLength]="2"
                    [required]="true"
                    pattern="^[^\/]{2,}$"
                    [forbiddenString]="projectsNames"
                    [inlineDisabled]="true"
                    (textChanged)="prepareProjectNameForChange($event)"
    >
      <span class="project-name"
            [smTooltip]="project.name?.includes('/') ? project.name : ''"
      >{{project.basename}}</span> -->
    </div>
    <!-- </sm-inline-edit> -->
    <div *ngIf="project.system_tags?.includes('default')" class="justify-content-end project-tag">Sample</div>
    <sm-project-card-menu-extended class="menu-wrapper" *ngIf="!hideMenu"
      (deleteProjectClicked)="deleteProjectClicked.emit($event)" (moveToClicked)="moveToClicked.emit($event)"
      (newProjectClicked)="newProjectClicked.emit($event)" (projectNameInlineActivated)="projectName.inlineActivated()"
      [project]="project">
    </sm-project-card-menu-extended>
  </div>
  <div class="w-100">
    <ng-template></ng-template>
    <div class="d-flex">
      <div style="width: 50%;" class="d-flex">
        <div style="width: 80%;">
          <sm-circle-counter
            [counter]="project.id === '*' ? '∞' : project.stats?.active?.status_count?.in_progress ?? '0'"
            label="Running"
            [type]="project.stats?.active?.status_count?.in_progress ===0 ? circleTypeEnum.empty : circleTypeEnum.running"
            [isTotal]="false"></sm-circle-counter>
        </div>
        <div style="width: 20%;"> 
          <img alt="running" src="app/webapp-common/assets/running.svg" width="14" height="14">
        </div>
      </div>
      <div class="line"></div>
      <div style="width: 50%;" class="d-flex">
        <div style="width: 80%;">
          <sm-circle-counter [counter]="project.id === '*' ? '∞' : project.stats?.active?.completed_tasks_24h ?? '0'"
            label="Completed"
            [type]="project.stats?.active?.completed_tasks_24h ===0 ? circleTypeEnum.empty : circleTypeEnum.completed"
            [isTotal]="false"></sm-circle-counter>
        </div>
        <div style="width: 20%;">
          <img alt="completed" src="app/webapp-common/assets/completed.svg" width="14" height="14">
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex-center" footer>
    <!-- <div class="tag"><i *ngIf="hidden" class="al-icon al-ico-ghost sm-md"></i></div> -->
    <div class="footer-title" *ngIf="project?.stats">
      Compute Time: {{computeTime}}
    </div>
    <div class="tag"></div>
  </div>
</sm-card>