<sm-compare-footer *ngIf="footerState">
  <ng-container *ngFor="let footerItem of footerItems; trackBy: trackBy">
    <ng-template
      #templateRef
      [ngTemplateOutletContext]="{ $implicit: footerItem.getItemState(footerState)}"
      [ngTemplateOutlet]="templateRef"
      let-footerItemState
    >
      <ng-container *ngIf="!footerItem.isTag; else tags">
        <span
          *ngIf="!footerItemState?.preventCurrentItem"
          [ngClass]="footerItemState?.wrapperClass"

          [smTooltip]="footerItem.disable || footerItemState.disable
                                  ? footerItem.disableDescription || footerItemState.disableDescription
                                  : footerItemState.description   || footerItem.description"
        >
          <button
            class="compare-button-container"
            [ngClass]="footerItem.class || footerItemState?.class"
            (click)="footerItem.emit && footerItemClick.emit({item: footerItem, emitValue: footerItemState?.emitValue })"
            [class.disabled]="footerItemState?.disable ? footerItemState?.disable : null"
          >
            <i *ngIf="footerItem.icon" data-id="FooterOptions" class="sm al-icon sm-md al-color"
               [class]="footerItem.icon"></i>
            <i *ngIf="footerItemState?.icon" data-id="archiveOption" class="sm al-icon sm-md al-color"
               [class]="footerItemState?.icon"></i>
            <div *ngIf="footerItem.title" class="title" data-id="compareOption">{{footerItem.title}}</div>
            <div *ngIf="footerItemState?.title" class="title ms-1">{{footerItemState?.title}}</div>
          </button>
        </span>
      </ng-container>
      <ng-template #tags>
        <span
          class="tags-container"
          [smTooltip]="footerItem.disable || footerItemState.disable ?
             footerItem.disableDescription || footerItemState.disableDescription :
             footerItemState.description   || footerItem.description"
        >
          <button
            [matMenuTriggerFor]="tagsMenu.matMenu"
            [disabled]="footerItemState.disable"
            (menuOpened)="tagMenuOpened(tagMenu)"
            (menuClosed)="tagMenuClosed(tagMenu)"
          ><i data-id="AddTagOption" class="al-icon sm-md pt-1" [class]="icons.TAG"></i></button>
        </span>

        <sm-tags-menu
          #tagsMenu
          class="light-theme"
          [tags]="footerItemState.tags"
          [tagsFilterByProject]="footerItemState.tagsFilterByProject"
          [projectTags]="footerItemState.projectTags"
          [companyTags]="footerItemState.companyTags"
          (tagSelected)="tagSelected.emit({tags: $event, emitValue: footerItemState.emitValue})">
        </sm-tags-menu>
      </ng-template>
    </ng-template>
  </ng-container>
</sm-compare-footer>
